<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('availablexams.examsavailable') }}
                    
                    div.text-center.mx-auto
                    div
                        nav.breadcrumb-one(aria-label="breadcrumb")
                            ol.breadcrumb
                              li.breadcrumb-item
                                a(href="javascript:void(0);") {{ $t('availablexams.dashboard') }}
                              li.breadcrumb-item.active(aria-current="page") {{ $t('availablexams.examsavailable') }}

                div.row.mt-5.pb-5
                    div.col-lg-12.col-md-12.layout-spacing
                        div.widget.box.box-shadow
                            div.widget-header
                                div.row.mt-4
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                            h3 {{ $t('availablexams.welcomeuser') }}
                                            h4 {{ $t('availablexams.following') }}

                                div.widget-content.widget-content-area
                                    div.col-md-8.mx-auto
                                        div.form-group.mb-4
                                            label(for="") {{ $t('availablexams.examsavailable') }}
                                            select.form-control(id="" v-model="idGroupDetail")
                                                option(value="0" selected) {{ $t('availablexams.selectexam') }}
                                                option(:value = "exam.idGroupDetail" v-for="exam in examsList") {{ exam.sExamName  }}({{exam.iOportunities}})
                                            
                                        a.btn.btn-success(@click="goToExam()") {{ $t('availablexams.presentexam') }}
</template>
<script>
/* eslint-disable */
export default {
    name: 'AvailableExams',
    data() {
        return {
            idGroupDetail: 0,
            examsList: [],
        }
    },
    methods: {
        async getExams() {
            await axios.get(apiURL + 'Student/getGroupExams?idPerson=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                console.log(response)
                this.examsList = response.data.resultList
            } )
            .catch( (error)  => {
                console.log("error")
            } )            
        },
        goToExam() {
            var sUrl = this.examsList.find(o => o.idGroupDetail === this.idGroupDetail).sPath
            Swal.fire({
            title: this.$t('msg.title')+ this.examsList.find(o => o.idGroupDetail === this.idGroupDetail).sExamName +'</b>?',
            html: this.$t('msg.html')+"<br/>"+ this.$t('msg.html1')+":" + JSON.parse(localStorage.getItem("dataPerson")).sName + " " + JSON.parse(localStorage.getItem("dataPerson")).sLastName + " <br/> " + this.$t('msg.email') + JSON.parse(localStorage.getItem("dataPerson")).sEmail,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('msg.buttoacept'),
            cancelButtonText: this.$t('msg.buttodecli')
            }).then((result) => {
            if (result.isConfirmed) {

                if(this.examsList.find(o => o.idGroupDetail === this.idGroupDetail).iOportunities > 2){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: this.$t('msg.text'),
                        footer: ''
                    })
                    return
                }
                else{
                    if (sUrl == ''){
                        this.prepareExam("G"+this.idGroupDetail)
                        router.push('/student/CoverPage/null/' + this.idGroupDetail + '/' + this.examsList.find(o => o.idGroupDetail === this.idGroupDetail).idExam)
                    }
                    else{
                        localStorage.id = "G"+this.idGroupDetail
                        this.prepareExam("G"+this.idGroupDetail)
                        router.push('/student/takeExam/' + sUrl)
                        }
                    }


                //
                
                //el idPersonExam realmente es el idGroupDetail 
                //this.prepareExam("G"+this.idGroupDetail)
                //localStorage.id = 'G' + this.idGroupDetail
                //router.push('/student/takeExam/' + sUrl)
            }
            })            
        },
        async prepareExam(id)
        {
            await axios.post(apiURL + 'Student/setIncompleteExam?id=' + id, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                console.log('Examne Marcado')
            } )
            .catch( (error) => {
                console.log('Error en el marcado del examen: ' + error)
            } )
        },        
    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.getExams()
    }
        

}
</script>                                                              